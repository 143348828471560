<template>
  <div class="col-md-6 offset-md-3 col-sm-12">
    <div class="mb-5">
      <img src="@/assets/lips.png">
      <h1>Bimbot</h1>
    </div>
    <AudioPlayer/>
    <HelloWorld/>
  </div>
</template>

<script>
import AudioPlayer from './components/AudioPlayer.vue'
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    AudioPlayer,
    HelloWorld
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  font-family: 'Lato', sans-serif;
}


</style>
