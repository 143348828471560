<template>
    <hr>
    <h2>Player</h2>
    <div class="alert alert-dark">
      <div class="row">
        <div class="col-md-auto" v-if="song">
          <img v-bind:src="'https://img.youtube.com/vi/'+slug+'/hqdefault.jpg'" id="yt-thumb">
        </div>
        <div class="col text-start">
          <p v-if="song">
            {{ song.title }}
          </p>
          <p v-else>
            Nic teraz nie gra!
          </p>
          <div class="progress">
            <div class="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" :style="{width: percent+'%'}" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div class="controls row">
            <div class="col-auto">
              <small>{{ current }}</small>
            </div>
            <div class="col-md text-end">
              <small>{{ total }}</small>
            </div>

          </div>
        </div>
      </div>
      <!--
      <hr>
      <div class="text-start">
        <div>

        </div>
      </div>
    -->
    </div>


  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'AudioPlayer',
    props: {
      msg: String
    },
  
    data () {
      return {
        song: null,
        current: "0:00:00",
        total: "0:00:00",
        percent: 0,
        url: "",
        slug: "",
      }
    },
  
    created: function() {

      update.call(this);

      // Fake update tick
      window.setInterval(() => {
        if (!this.song) {
          this.percent = 0;
          return;
        }

        // Handle current
        var curtotal = null;
        {
          var d = this.current.split(":");
          var h = Number(d[0]);
          var m = Number(d[1]);
          var s = Number(d[2]);

          s += 1;
          if (s >= 60) {
            s = 0;
            m += 1;
          }

          if (m >= 60) {
            m = 0;
            h += 1;
          }

          curtotal = (h * 60 * 60) + (m * 60) + s;

          this.current = h + ":" + ("0" + m).slice(-2) + ":" + ("0" + s).slice(-2)
        }

        // Handle total and percent
        {
          d = this.total.split(":");
          h = Number(d[0]);
          m = Number(d[1]);
          s = Number(d[2]);

          var totaltotal = (h * 60 * 60) + (m * 60) + s;
          this.percent = (curtotal / totaltotal) * 100;
          this.percent = Math.floor(this.percent);
        }
      }, 1000)

      // Real update tick
      window.setInterval(() => {
        update.call(this);
      }, 5000)

    }
  }

  function update() {
    axios.get('https://api-bimbot.cybryda.org/youtube')
          .then(response =>{
            if (!response.data.total) {
              this.song = null;
              this.total = "0:00:00";
              this.current = "0:00:00";
              this.percent = 0;
              return;
            }
            this.song = response.data;
            this.total = response.data.total;
            this.current = response.data.current;
            this.percent = Math.floor(response.data.percent);
            this.slug = youtube_parser(response.data.url);
          })
  }
  
  function youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }


  </script>
  
  <style>
  .clead {
    font-size: 1.25rem;
  }
  .progress {
    height:5px;
  }
  .alert-dark {
    background-color: #212529;
    color: #fff;
  }
  .controls {
    text-align: left;
  }
  #yt-thumb {
    max-width: 100px;
  }
  </style>
  